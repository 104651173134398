import React from 'react';
import { Link } from 'react-scroll';
import { useHistory } from 'react-router-dom';

const NavItem = ({ text, link, duration }) => {
  const history = useHistory();

  const handleClick = () => {
    const targetElement = document.getElementById(link);
    if (!targetElement) {
      // If the target element is not found, navigate to the homepage
      history.push('/');
      setTimeout(() => {
        const updatedTargetElement = document.getElementById(link);
        if (updatedTargetElement) {
          // If the target element is found on the homepage, scroll to it
          window.scrollTo({
            behavior: 'smooth',
            top: updatedTargetElement.offsetTop,
          });
        }
      }, 1000); // Adjust the timeout value as needed
    }
  };

  return (
    <Link
      activeClass="active"
      to={link}
      spy={true}
      smooth={true}
      offset={0}
      duration={duration}
      onClick={handleClick}
    >
      {text}
    </Link>
  );
};

export default NavItem;
