import icon from '@plone/volto/icons/list-bullet.svg'
import BlockEdit from './Block/Edit';
import BlockView from './Block/View';

export default function applyConfig(config) {
    config.blocks.blocksConfig.leaflet_minimap = {
        id: 'leaflet_minimap',
        title: 'Leaflet Minimap',
        edit: BlockEdit,
        view: BlockView,
        icon: icon,
        group: 'common',
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
    };
    return config;
};
