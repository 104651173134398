/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { List, Segment } from 'semantic-ui-react';
import NavItem from '../Navigation/NavItem';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { toBackendLang } from '@plone/volto/helpers';

/**
 * Component to display the footer.
 * @function Footer
 * @returns {string} Markup of the component
 */
const Footer = () => {
  
  const lang = useSelector((state) => state.intl.locale);
  var text1,text2,text3,text4,text5,loginlink = ""

  loginlink = `/${toBackendLang(lang)}/login`

  if (lang=="de") {
    text1="Startseite"
  } else{
    text1="Home"
  }
  if (lang=="de") {
    text2="Für Betroffene"
  } else{
    text2="For patients"
  }
  if (lang=="de") {
    text3="Für Behandler"
  } else{
    text3="For practitioners"
  }
  if (lang=="de") {
    text4="Aktuelles"
  } else{
    text4="Latest News"
  }
  if (lang=="de") {
    text5="Kontakt"
  } else{
    text5="Contact"
  }
  
  return (
    <Segment className="footer">
        <div className='ui container twelve column grid column-grid' >
          <div className="four wide computer twelve wide mobile four wide tablet column column-blocks-wrapper wrap-overflow">
            <p>
              <b>
              <FormattedMessage
                id="impressum"
                defaultMessage="Imprint" />
              </b>
              <br />
              Universitätsklinikum Carl Gustav Carus
              an der Technischen Universität Dresden
              Klinik und Poliklinik für Psychiatrie
              <br />
              Direktorin: Prof. Dr. med. habil. Vjera Holthoff-Detto
              <br />
              Fetscherstraße 74 D-01307 Dresden
              <br />
              Telefon:
              <a href="tel:+49(0) 351 458 2760"> +49(0) 351 458 2760</a>
              <br />
              Telefax: +49(0) 351 458 4324
              <br />
              <a href="https://ukdd.de/psy">https://ukdd.de/psy</a>          
            </p>
            <p>
              
              <FormattedMessage
                id="privacy_policy_link"
                defaultMessage="The <a>privacy policy</a> of the University Hospital applies"
                values={{
                  a: msg => (
                    <a class="external_link" target="_blank" href="https://www.uniklinikum-dresden.de/de/impressum#Datenschutz">
                      {msg}
                    </a>
                  )
                }}
              />

            </p>
          </div>
          <div className="four wide computer twelve wide mobile four wide tablet column column-blocks-wrapper wrap-overflow">
            <p>
              <b>
              <FormattedMessage
                id="contact"
                defaultMessage="Contact" />
              </b>
              <br />
              Universitätsklinikum Carl Gustav Carus
              an der Technischen Universität Dresden
              Klinik und Poliklinik für Psychiatrie und Psychotherapie
              Früherkennungszentrum DD früh dran!
              <br />
              Haus 15, 3. Obergeschoss
              Fetscherstraße 74
              01307 Dresden
              <br />
              <a href="mailto:fruehdran@uniklinikum-dresden.de">fruehdran@uniklinikum-dresden.de</a>
            </p></div>
          <div className="four wide computer twelve wide mobile four wide tablet column column-blocks-wrapper wrap-overflow">
            <p>
              <b>
              <FormattedMessage
                id="quick_access"
                defaultMessage="Quick Access" />
              </b>
            </p>
            <List className="siteactions" relaxed>
              
              <div role="listitem" className="item">
                <a href={loginlink}>Login</a>
              </div>
               
              <div role="listitem" className="item">
                <NavItem text={text1} link={"pos1"} duration={250} lang={lang}/>
              </div>
              <div role="listitem" className="item">
                <NavItem text={text2} link={"pos2"} duration={250} lang={lang}/>
              </div>
              <div role="listitem" className="item">
                <NavItem text={text3} link={"pos3"} duration={250} lang={lang} />
              </div>
              <div role="listitem" className="item">
                <NavItem text={text4} link={"pos4"} duration={250} lang={lang} />
              </div>
              <div role="listitem" className="item">
                <NavItem text={text5} link={"pos5"} duration={250} lang={lang} />
              </div>

            </List>

          </div>
          <div className="four wide computer twelve wide mobile four wide tablet column column-blocks-wrapper wrap-overflow">
            <p>Copyright © {new Date().getFullYear()}</p>
          </div>

        </div>
      </Segment>
  )

}

export default Footer;