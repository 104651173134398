import icon from '@plone/volto/icons/list-bullet.svg'
import BlockEdit from './Block/Edit';
import BlockView from './Block/View';
export default function applyConfig(config) {
    config.blocks.blocksConfig.kontakt_formular = {
        id: 'kontakt_formular',
        title: 'Kontakt Formular',
        edit: BlockEdit,
        view: BlockView,
        icon: icon,
        group: 'common',
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
    };
    return config;
};
