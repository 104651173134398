import React from 'react';
import loadable from '@loadable/component';
import '../style/style.less';

const Kontakt = loadable(() => import('./Kontakt'), {
  ssr: false,
});

const View = (props) => {
  const { data } = props;

  return <Kontakt data={data} edit={false}/>;

};
export default View;