import { defineMessages } from 'react-intl';


export const ILeafletMapSchema = (intl) => ({
  title: intl.formatMessage(messages.map),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['posx', 'posy', 'zoom', 'height', 'width', 'overlay_url', 'markerpos'],
    },
  ],

  properties: {
    posx: {
      title: intl.formatMessage(messages.posx),
      type: 'number',
      initialValue: 2.5,
    },
    posy: {
      title: intl.formatMessage(messages.posy),
      type: 'number',
      initialValue: 2.5,
    },
    zoom: {
      title: intl.formatMessage(messages.zoom),
      type: 'number',
      initialValue: 8,
      maximum: 13,
      minimum: 5,
    },
    height: {
      title: intl.formatMessage(messages.height),
      initialValue: '300px',
    },
    width: {
      title: intl.formatMessage(messages.width),
      initialValue: '100%',
    },
    overlay_url: {
      title: intl.formatMessage(messages.overlay),
      widget: 'object_browser',
      mode: 'image',
      allowExternals: true,
      return: 'single',
    },
    markerpos: {
      title: "MarkerPosition",
      initialValue: '1,1',
    },
  },
  required: [],
});

const messages = defineMessages({
  map: {
    id: 'Map',
    defaultMessage: 'Map',
  },
  posx: {
    id: 'posx',
    defaultMessage: 'Y-Position',
  },
  posy: {
    id: 'posy',
    defaultMessage: 'X-Position',
  },
  zoom: {
    id: 'Zoom',
    defaultMessage: 'Zoom',
  },
  height: {
    id: 'Height',
    defaultMessage: 'Height',
  },
  width: {
    id: 'Width',
    defaultMessage: 'Width',
  },
  icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  website: {
    id: 'Website',
    defaultMessage: 'Website',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  marker: {
    id: 'Marker',
    defaultMessage: 'Marker',
  },
  markers: {
    id: 'Markers',
    defaultMessage: 'Markers',
  },
  content: {
    id: 'Content',
    defaultMessage: 'Content',
  }, 
  overlay: {
    id: 'Overlay',
    defaultMessage: 'Overlay',
  },

});
