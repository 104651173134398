import React from 'react';
import { RenderBlocks } from '@plone/volto/components';
import { Link } from 'react-scroll';

const View = (props) => {
    const { data } = props;
    const metadata = props.metadata || props.properties;
    const CustomTag = `${data.as || 'div'}`;
    
     //const style_string = {zindex: data.z-index}
    return (
        
        <Link
            activeClass="active"
            to={data.link}
            spy={true}
            smooth={true}
            offset={parseInt(data.offset)}
            duration={parseInt(data.duration)}
        >
            <CustomTag className={data.classes}>
                <RenderBlocks {...props} metadata={metadata} content={data?.data || {}} />
            </CustomTag>
        </Link>
    );
};

export default View;