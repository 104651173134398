import React from 'react';
import NavItem from './NavItem';

const NavItems = ({ items, lang }) => {
    
  if (lang=="de") {
    return (
      <>
        <NavItem text={"Startseite"} link={"pos1"} duration={250} lang={lang}/>
        <NavItem text={"Für Betroffene"} link={"pos2"} duration={300} lang={lang}/>
        <NavItem text={"Für Behandler"} link={"pos3"} duration={350} lang={lang}/>
        <NavItem text={"Aktuelles"} link={"pos4"} duration={400} lang={lang}/>
        <NavItem text={"Kontakt"} link={"pos5"} duration={450} lang={lang}/>
      </>
    );  
  } else {
    return (
      <>
        <NavItem text={"Home"} link={"pos1"} duration={250} lang={lang}/>
        <NavItem text={"For patients"} link={"pos2"} duration={300} lang={lang}/>
        <NavItem text={"For practitioners"} link={"pos3"} duration={350} lang={lang}/>
        <NavItem text={"Latest news"} link={"pos4"} duration={400} lang={lang}/>
        <NavItem text={"Contact us"} link={"pos5"} duration={450} lang={lang}/>
      </>
    );
  }

  
};

export default NavItems;
